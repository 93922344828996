var LazyLoad = require('vanilla-lazyload/dist/lazyload.min.js');

$( document ).ready(function() {
    var lazyLoadInstance = new LazyLoad({
        unobserve_entered: true,
        cancel_on_exit: false,
        callback_enter: executeLazyFunction
    });
    lazyLoadInstance.update();
    
    $("body").on("UpdateLoadImage", function() {
        lazyLoadInstance.update();
    });
    $("body").on("LoadSliderImages", function() {
        lazyLoadInstance.loadAll();
    });
});

window.lazyFunctions = {
    UpdateSliderImageElement: function (element) {
        if(!($(element).closest('body').hasClass('sliderLoaded'))){
            $('body').trigger('LoadSliderImages');
            $(element).closest('body').addClass('sliderLoaded');
        }
    }
};

function executeLazyFunction(element) {
    var lazyFunctionName = element.getAttribute("data-lazy-function");
    var lazyFunction = window.lazyFunctions[lazyFunctionName];

    if (!lazyFunction) return;
    lazyFunction(element);
}